//选择课程
<template>
    <div class="choose_lesson">
        <div class="content" v-if="!showLoading">
            <!-- 返回和章节名称 -->
            <div class="top_msg">
                <div @click.stop="goBack" class="back iconfont cloud-fanhui"></div>
                <div class="name">{{ chapterName }}</div>
            </div>

            <!-- 非Python章节显示的课程列表 -->
            <div class="show_lesson" v-if="chapterType == 1 && lessonData.length > 0">

                <div :class="['item_lesson']" v-for="(item, index) in lessonData" :key="index">

                    <!-- 图片 -->
                    <img class="img_lesson" :src="item.coursewareCover" />

                    <!-- 标题和内容 -->
                    <div class="title_content">
                        <div class="item_title">{{ item.coursewareName }}</div>
                    </div>

                    <!-- 开始学习的按钮 -->
                    <div class="bt_study" @click.stop="startStudyLesson(item)">
                        开始学习
                    </div>
                </div>

            </div>

            <!-- Python章节显示的表格课程 -->
            <div class="show_table" v-if="chapterType != 1 && lessonData.length > 0">
                <!-- 表头部分 -->
                <div class="table_title item_height_head">
                    <div class="table_one com_flex border_right">序号</div>
                    <div class="table_two com_flex border_right">课程主题</div>
                    <!-- <div class="table_three com_flex border_right">知识技能</div> -->
                    <div class="table_four com_flex">进度</div>
                </div>

                <!-- 表格内容部分 -->
                <div :class="['item_table_lesson','item_height',(index + 1) % 2 == 0 ? 'signle_bg' : 'double_bg']" v-for="(item, index) in lessonData" :key="index">

                    <div class="table_one com_flex border_right">{{ index + 1 }}</div>

                    <div class="table_two com_flex border_right">{{item.chapterName }}</div>

                    <!-- style="white-space: pre-wrap;" -->
                    <!-- <div class="table_three item_lesson_name com_flex border_right">
                        <div class="name_details" v-for="(lessonName,index) in item.lessonArr" :key="index">{{lessonName}}</div>
                    </div> -->

                    <div class="table_four com_flex">
                        <div class="bt_study" @click.stop="startStudyLesson(item)">
                            开始学习
                        </div>
                    </div>
                </div>
            </div>

            <!-- 空视图 -->
            <el-empty description="暂无数据~" v-if="showEmptyView && lessonData.length == 0"></el-empty>
        </div>
        <!-- 加载框 -->
        <Loading v-else></Loading>
    </div>
</template>
<script>
export default {
    name: "ChooseLesson",
    data() {
        return {
            //是否显示loading
            showLoading: true,

            //是否是Python类型的课程,需要选择课程界面传递过来
            chapterType: "",

            //章节名称
            chapterName: "",

            //课程章节Id
            curriculumChapterId: "",

            //课程ID
            curriculumCenterId: "",

            //是否是Python
            isPythonType: false,

            //是否显示空视图的标识
            showEmptyView: false,

            //课程列表的数据
            lessonData: [],
        };
    },
    created() {
        //获取一下服务器的最新的用户信息
        this.httpUserInfo();
    },
    methods: {
        //开始学习
        startStudyLesson(item) {
            //根据是否有权限来进行开始学习按钮的点击事件的处理
            if (item.permissionId != 0) {
                //这里根绝选择的不同课程来确定学习界面
                const obj = new Object();
                obj.curriculumCenterId = this.curriculumCenterId;
                obj.curriculumChapterId = this.curriculumChapterId;
                obj.chapterClassId = item.chapterClassId;
                obj.sectionType = item.sectionType;
                this.$router.push({ path: "/Study", query: obj });
            } else {
                let role = this.getUserRole();
                if (role == 0) {
                    //学生
                    this.showWarnMsg("暂无课程访问权限，请联系班级老师！");
                } else if (role == -1) {
                    //游客
                    this.showWarnMsg("您当前身份是游客，暂无访问权限");
                } else if (role == 2 || role == 3) {
                    //老师或者校长
                    this.showWarnMsg("暂无课程访问权限！");
                }
            }
        },

        //返回
        goBack() {
            this.$router.back(-1);
        },

        //请求用户信息的接口
        async httpUserInfo() {
            //获取传递的参数
            this.chapterType = this.$route.query.chapterType;
            this.curriculumCenterId = this.$route.query.curriculumCenterId;
            this.chapterName = this.$route.query.chapterName;
            this.curriculumChapterId = this.$route.query.curriculumChapterId;
            let postId = this.$route.query.curriculumChapterId;
            // if (postId != this.curriculumChapterId) {
            //   //解决页面后退的时候刷新数据
            // this.curriculumChapterId = postId;
            this.showLoading = true;
            // }

            let userInfo = this.getUserInfo();
            let param = new Object();
            param.type = 5;
            param.uid = userInfo.uid;
            param.token = userInfo.token;
            let res = await this.$http.fetchPost(this.$api.LOGIN, param);
            if (res.data.state == 200) {
                //同步一下本地的用户角色
                userInfo.role = res.data.userInfo.role;
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                //在请求页面数据
                this.httpData();
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },

        //获取列表数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 3;
            param.curriculumCenterId = this.curriculumCenterId;
            param.curriculumChapterId = this.curriculumChapterId;
            let res = await this.$http.fetchPost(
                this.$api.LESSON_CENTER,
                param
            );

            if (res.data.state == 200) {
                //关闭Loading
                this.showLoading = false;

                //表示请求成功
                this.lessonData = res.data.data;

                if (
                    !this.isEmpty(this.lessonData) &&
                    this.lessonData.length > 0
                ) {
                    //循环给每个条目加入一个响应式的控制遮罩的值
                    this.lessonData.forEach((item) => {
                        this.$set(item, "isShow", false);

                        //给每个子条目添加一个课程的集合
                        // let lessonArr = item.coursewareName.split("=");
                        // this.$set(item, "lessonArr", lessonArr);
                    });
                } else {
                    //显示空视图
                    this.showEmptyView = true;
                }
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },
    },
    computed: {},
    components: {},
    activated() {
        //获取一下服务器的最新的用户信息
        // this.httpUserInfo();
    },
};
</script>
<style lang="scss" scoped>
.choose_lesson {
    background: $common_gray;
    height: calc(100vh - 87px);
    overflow-y: auto;

    .content {
        height: calc(100vh - 87px - 20px);
        display: flex;
        padding-top: 20px;
        // padding-bottom: 20px;
        width: $common_width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        //顶部返回与章节名称
        .top_msg {
            height: 55px;
            display: flex;
            align-items: center;
            .back {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 22px;
                font-weight: bold;
                height: 36px;
                width: 36px;
                background: rgba($color: #43bb61, $alpha: 0.6);
                border-radius: 50%;
            }

            .name {
                margin-left: 14px;
                min-width: 184px;
                padding: 0 10px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                background: $common_bg;
                border-radius: 22px;
            }
        }

        //滚动条
        // .el-scrollbar {
        // }

        // ::v-deep .el-scrollbar__wrap {
        //     overflow-y: auto;
        //     overflow-x: hidden;
        // }

        //展示列表课程的内容
        .show_lesson {
            margin-top: 12px;
            margin-right: 20px;
            display: flex;
            flex-wrap: wrap;
            .item_lesson {
                margin-bottom: 28px;
                position: relative;
                width: 100%;
                height: 212px;
                background: white;
                border-radius: 8px;
                align-items: center;
                display: flex;
                cursor: pointer;
                .img_lesson {
                    width: 140px;
                    height: 112px;
                    margin-left: 42px;
                }

                //标题和内容介绍
                .title_content {
                    margin-left: 24px;
                    color: black;
                    .item_title {
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .item_content {
                        margin-top: 30px;
                        font-size: 16px;
                    }
                }

                //开始学习按钮
                .bt_study {
                    position: absolute;
                    cursor: pointer;
                    right: 0;
                    width: 154px;
                    height: 54px;
                    background: #fed420;
                    border-radius: 42px;
                    margin-right: 42px;
                    color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: bold;
                }

                .mengban {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    position: absolute;
                    background: rgba($color: #000000, $alpha: 0.2);
                }
            }

            .item_lesson:hover {
                background: rgba($color: #000000, $alpha: 0.2);
            }

            .hasMargin {
                margin-right: 60px;
            }

            .noMargin {
                margin-right: 0px;
            }
        }

        //展示表格的课程内容
        .show_table {
            height: calc(100vh - 87px - 40px - 55px);
            overflow: auto;
            margin-top: 12px;

            //表头部分
            .table_title {
                display: flex;
                background: $common_bg;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                color: white;
                font-size: 16px;
                font-weight: bold;
            }

            //表格内容部分
            .item_table_lesson {
                flex: 1;
                overflow: hidden;
                display: flex;
                font-size: 16px;
                border-top: 2px solid white;

                .item_lesson_name {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .name_details {
                        margin: 4px 0;
                    }
                }
            }

            .table_one {
                flex: 2;
            }
            .table_two {
                flex: 3;
            }
            .table_three {
                flex: 4;
            }
            .table_four {
                flex: 3;
                display: flex;
                //开始学习按钮
                .bt_study {
                    cursor: pointer;
                    right: 0;
                    width: 140px;
                    height: 54px;
                    background: #f7a901;
                    border-radius: 42px;
                    color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .com_flex {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .border_right {
                border-right: 2px solid white;
            }

            .item_height_head {
                min-height: 68px;
            }

            .item_height {
                min-height: 99px;
            }

            .signle_bg {
                background: #a1daaf;
            }

            .double_bg {
                background: #cfe8d3;
            }
        }

        .show_table::-webkit-scrollbar {
            display: none;
        }

        //显示空视图
        .empty_view {
            margin: 0 auto;
            margin-top: 10%;
            color: $text_gray;
        }
    }
}

//去除滚动条
.choose_lesson::-webkit-scrollbar {
    display: none;
}
</style>